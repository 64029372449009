<template>
  <div class="account-dropdown">
    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <base-svg-icon
          v-if="mobile == true"
          icon-id="setting"
          icon-viewbox="0 0 23.469 25.246"
        >
        </base-svg-icon>
        <div class="userProfile" v-else>
          <div class="userName">{{ user.first_name }} {{ user.last_name }}</div>
          <span v-if="isLoading">
            <base-skeleton-loader
              type="header"
              :count="1"
            ></base-skeleton-loader>
          </span>
          <span v-else
            ><img
              :src="`${$config.IMG_HOST}/160x160/${user.avatar}`"
              alt=""
              class="img-fluid"
              v-if="user.avatar"
          /></span>
          <svg-icon icon-id="dropdown" icon-viewbox="0 0 14.828 8.414">
          </svg-icon>
        </div>
      </template>
      <!-- <b-dropdown-item
        :to="`/${user.type == 0 ? 'user' : 'brand'}/edit-profile`"
      > -->
      <b-dropdown-item
        :to="user.type == 0 ? $i18nRoute({name: 'userEditProfile'}) : $i18nRoute({name: 'brandEditProfile'})"
      >
        <span class="icon"
          ><base-svg-icon
            icon-id="profile"
            view-box="0 0 8.741 11.103"
          ></base-svg-icon></span
        >{{ $t("global.edit_profile") }}
      </b-dropdown-item>
      <b-dropdown-item
        :to="user.type == 0 ? $i18nRoute({name: 'userTools'}) : $i18nRoute({name: 'brandTools'})"
        v-if="user.displayTools"
      >
        <span class="icon"
          ><base-svg-icon
            icon-id="setting"
            view-box="0 0 8.741 11.103"
          ></base-svg-icon></span
        >Tools
      </b-dropdown-item>
      <b-dropdown-item @click="logoutUser(user.type)">
        <span class="icon"
          ><base-svg-icon
            icon-id="logout"
            view-box="0 0 11.217 11.565"
          ></base-svg-icon></span
        >{{ $t("logout") }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { MESSAGES } from "@/_helpers/notifications";
import { Trans } from "@/plugins/Translation";

export default {
  name: "InstarsfrontAccountdropdown",
  props: {
    mobile: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 700);
  },

  methods: {
    ...mapActions(["logOut"]),
    async logoutUser(userType) {
      await this.logOut().then(() => {
        this.notify("success", MESSAGES.LOGGED_OUT);
        if (userType == 0) {
          // console.log(this.$i18nRouter({name: 'userloginOrRegister'}))
          this.$router.push(this.$i18nRoute({name: 'userloginOrRegister'}));
          // this.$router.push("/user/login");
        } else {
          this.$router.push(this.$i18nRoute({name: 'brandloginOrRegister'}));
          // this.$router.push("/brand/login");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-dropdown {
  ::v-deep .dropdown {
    .dropdown-menu {
      width: 194px;
      border: 0;
      border-radius: 0 0 15px 15px;
      top: 20px !important;
      right: 0;
      overflow: hidden;
      box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 25%);
      li:not(:last-child) {
        border-bottom: solid 1px #e9edf5;
      }
    }
    .dropdown-toggle {
      padding: 0px;
      width: auto;
      font-size: rem(14px);
      font-weight: 600;
    }
    .dropdown-item {
      color: #253858;
      font-size: rem(14px);
      font-weight: 600;
      padding-left: rem(18px);
      span {
        width: 12px;
        padding-right: 10px;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .userProfile {
    display: flex;
    align-items: center;
    color: var(--textPrimary);
    span {
      width: rem(50px);
      min-width: rem(50px);
      height: rem(50px);
      border-radius: 100%;
      background: #e8e8f0;
      display: inline-block;
      margin-left: 13px;
      margin-right: 20px;
      overflow: hidden;
      @media screen and (max-width: 1199px) {
        width: rem(35px);
        min-width: rem(35px);
        height: rem(35px);
        margin-right: 13px;
      }
    }
    svg {
      width: 12px;
      transition: 0.5s ease-in;
    }
  }
}
.account-dropdown[data-v-52fc96a6]{
   .dropdown {
    .dropdown-toggle{
       .userName{
            max-width:120px;
            white-space: break-spaces;
          }
    }
   }
}
</style>
